exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3K5sldkGLn0wH1yNYzfABL {\n    display: inline-block;\n    width: 100%;\n}\n\n._39zdR8QD5c9ijcUT43rJh9 {\n    float: left;\n    margin-right: 10px;\n}", ""]);

// exports
exports.locals = {
	"listGroupItem": "_3K5sldkGLn0wH1yNYzfABL",
	"itemHandle": "_39zdR8QD5c9ijcUT43rJh9"
};