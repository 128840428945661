exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1it_vQIWbWWVJ4rTrsTjqT {\n    float: right;\n    margin-top: 10px;\n}\n\n._1Gnd93BIIJu0En1yACTVCv {\n    float: right;\n    margin-top: 10px;\n}\n\n._3VGKiPqR9u7uKzGX4i2ilR .header {\n    font-size: 1rem; /* theme.fonts.size.body */\n    padding: 10px 0;\n    min-height: 20px;\n}\n\n._3VGKiPqR9u7uKzGX4i2ilR:not(._28CBcIp6YLlLvbxxCh0F26) .description {\n    margin-left: 20px;\n}\n\n._3VGKiPqR9u7uKzGX4i2ilR .form-group {\n    margin-bottom: 0;\n}\n\n._28CBcIp6YLlLvbxxCh0F26 {\n    margin-left: 0;\n}\n", ""]);

// exports
exports.locals = {
	"headerComponentsWrapper": "_1it_vQIWbWWVJ4rTrsTjqT",
	"itemActionsWrapper": "_1Gnd93BIIJu0En1yACTVCv",
	"itemWrapper": "_3VGKiPqR9u7uKzGX4i2ilR",
	"itemWrapperStatic": "_28CBcIp6YLlLvbxxCh0F26"
};