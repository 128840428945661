/*
 * Copyright (C) 2020 Graylog, Inc.
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the Server Side Public License, version 1,
 * as published by MongoDB, Inc.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * Server Side Public License for more details.
 *
 * You should have received a copy of the Server Side Public License
 * along with this program. If not, see
 * <http://www.mongodb.com/licensing/server-side-public-license>.
 */
import PropTypes from 'prop-types';
import React from 'react';

import StringUtils from 'util/StringUtils';

/**
 * Component that will render a singular or plural text depending on a given value.
 */
class Pluralize extends React.Component {
  static propTypes = {
    /** Singular form of the word. */
    singular: PropTypes.string.isRequired,
    /** Plural form of the word. */
    plural: PropTypes.string.isRequired,
    /** Value to use to decide which form will be rendered. */
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]).isRequired,
  };

  render() {
    return <span>{StringUtils.pluralize(this.props.value, this.props.singular, this.props.plural)}</span>;
  }
}

export default Pluralize;
