exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2NwaFVSf80fmZscksRCYco {\n    padding-left: 0;\n    padding-right: 0;\n}\n\n._2NwaFVSf80fmZscksRCYco .popover-content {\n    min-width: 200px;\n    padding: 0;\n}\n\n._2NwaFVSf80fmZscksRCYco .list-group {\n    margin-bottom: 0;\n}\n\n._2NwaFVSf80fmZscksRCYco .list-group-item {\n    border-right: 0;\n    border-left: 0;\n    padding: 6px 15px;\n}\n\n._2NwaFVSf80fmZscksRCYco .list-group-item:first-child {\n    border-top-right-radius: 0;\n    border-top-left-radius: 0;\n}\n\n._2NwaFVSf80fmZscksRCYco .list-group-item:last-child {\n    border-bottom: 0;\n}\n\n._1U3FdrQdZY4GXU7aaRDwq0 {\n    max-height: 340px; /* 10 items */\n    overflow: auto;\n}\n\n.fJLXbiFrKPQcmZBohb5LI {\n    margin-bottom: 0 !important;\n    padding: 5px;\n}\n", ""]);

// exports
exports.locals = {
	"customPopover": "_2NwaFVSf80fmZscksRCYco",
	"scrollableList": "_1U3FdrQdZY4GXU7aaRDwq0",
	"dataFilterInput": "fJLXbiFrKPQcmZBohb5LI"
};