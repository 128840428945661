exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._26CnxIiLVQOPzfY4a1LSTK {\n    padding-left: 0;\n    margin-bottom: 20px;\n}\n\n._26CnxIiLVQOPzfY4a1LSTK ._26CnxIiLVQOPzfY4a1LSTK {\n    margin-bottom: 0;\n}\n", ""]);

// exports
exports.locals = {
	"list": "_26CnxIiLVQOPzfY4a1LSTK"
};